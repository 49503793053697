/**
 * This file contains profile completion logic.
 * There is two usecases:
 *  - Profile Completion PopUp:
 *    if the completion popup markup is included in the page, it may fetch the
 *    the percentage from the API, update the markup with it and display it.
 *  - Edit Profile Progress bar
 *    on the edit profile pages, style the completion progress bar included in the page.
 */

import * as Sentry from "@sentry/react";

import axios from "@utils/axios";

import { GetResponse } from "../shared/lib";

const API_PATH = "/api/v1/profile-completion-status/";

const COMPLETION_DISMISSED_TIMESTAMP_STORAGE_PATH =
  "profile.completion.dismissed.timestamp";

/**
 * get profile completion percentage from API request
 */
const getCompletionPercentage = async (): Promise<number | null> => {
  try {
    const result = await axios.get<GetResponse<200, typeof API_PATH>>(API_PATH);
    return result.data.percentage;
  } catch (error) {
    Sentry.captureMessage(`Error retrieving completion percentage: ${error}`);
    return null;
  }
};

const getProgressBarElements = () =>
  document.getElementsByClassName("profile-progress-bar");

/**
 * set value of the progress bar from the input
 * used only for the popup
 */
const setupPopupProgressBarsPercentage = (percentage: number): void => {
  const progressBars = getProgressBarElements();
  if (!progressBars.length) {
    return;
  }
  const progressBar = progressBars[0] as HTMLElement;
  progressBar.setAttribute("value", `${percentage}`);

  const title = document.getElementsByClassName(
    "completion__title",
  )[0] as HTMLElement;
  title.textContent = `${percentage}% completed`;
};

enum CompletionLevel {
  intermediate = "intermediate",
  advanced = "advanced",
  professional = "professional",
  brightStar = "bright-star",
}

const completionLevelLabels: { [key in CompletionLevel]: string } = {
  intermediate: window.gettext("intermediate"),
  advanced: window.gettext("advanced"),
  professional: window.gettext("professional"),
  "bright-star": window.gettext("bright-star"),
};

/**
 * set styling of the progress bar given its value
 * used for both popup and Profile Edit
 */
const setupProgressBarsStyling = () => {
  const profileProgressColourCoding = (
    value: number,
  ): CompletionLevel | null => {
    switch (true) {
      case value < 50:
        return CompletionLevel.intermediate;
      case value < 75:
        return CompletionLevel.advanced;
      case value < 100:
        return CompletionLevel.professional;
      case value === 100:
        return CompletionLevel.brightStar;
      default:
        return null;
    }
  };

  const progressBars = getProgressBarElements();
  if (!progressBars.length) {
    return;
  }
  const valueNow = parseInt(
    progressBars[0].getAttribute("value") as string,
    10,
  );

  const strength = document.querySelector("#strength");
  const classification = profileProgressColourCoding(valueNow);
  if (classification !== null) {
    [...progressBars].forEach((bar) => {
      bar.classList.add(`${classification}`);
      if (strength) {
        const label = completionLevelLabels[classification];
        strength.innerHTML = `<span>${label}</span>`;
      }
    });
  }
};

const setupPopUpDismissButton = (component: HTMLElement): void => {
  const dismissBtn = document.querySelector(
    "#dismiss-profile-prompt",
  ) as HTMLButtonElement;

  // dismiss component
  dismissBtn.addEventListener("click", (e) => {
    e.preventDefault();
    component.classList.remove("show");
    localStorage.setItem(
      COMPLETION_DISMISSED_TIMESTAMP_STORAGE_PATH,
      String(Math.round(Date.now())),
    );
  });
};

const didUserDismissThePopupRecently = (): boolean => {
  const dismissedTimestamp = localStorage.getItem(
    COMPLETION_DISMISSED_TIMESTAMP_STORAGE_PATH,
  );

  if (dismissedTimestamp) {
    const expiry = new Date();
    const expiryTimestamp = expiry.setMonth(expiry.getMonth() - 3);
    const expired = parseInt(dismissedTimestamp, 10) < expiryTimestamp;
    if (!expired) {
      return true;
    }
  }
  return false;
};

/**
 * Host the logic to decide to show the popup if needed
 */
const setupProfileCompletionPopUp = async (
  component: HTMLElement,
): Promise<void> => {
  if (!component || didUserDismissThePopupRecently()) {
    return;
  }

  const completionPercentage = await getCompletionPercentage();

  if (completionPercentage !== null) {
    if (completionPercentage < 100) {
      setupPopUpDismissButton(component);
      setupPopupProgressBarsPercentage(completionPercentage);
      setupProgressBarsStyling();
      component.classList.add("show");
    }
  }
};

// setup progress bar styling (edit profile case)
const progressBars = getProgressBarElements();
if (progressBars.length) {
  setupProgressBarsStyling();
}

// setup popup (popup case)
const component = document.querySelector(
  ".completion__prompt",
) as HTMLElement | null;
if (component) {
  setTimeout(() => setupProfileCompletionPopUp(component), 5000);
}
