import axios from "axios";

const api = axios.create({
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});

api.interceptors.request.use(async (config) => {
  if (window.AwsWafIntegration) {
    try {
      const token = await window.AwsWafIntegration.getToken();
      if (token) {
        config.headers.set("x-aws-waf-token", token);
      }
    } catch (error) {
      console.error("Error getting token:", error);
    }
  }
  return config;
});

export default api;
