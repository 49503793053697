import axios from "@utils/axios";

import { consentToAll, rejectAll } from "./cookies";

const cookiePrompt = document.querySelector("#js-cookie-prompt__container");

// Track response for metrics
// This request also triggers middleware which sets cookies if consent was granted
const trackResponse = (accepted: boolean) => {
  return axios.post("/api/v2/metrics/cookie-response/", { accepted });
};

const acceptAll = (e: MouseEvent) => {
  e.preventDefault();

  consentToAll();
  trackResponse(true);
  cookiePrompt?.classList.remove("show");
};

const rejectAllCookies = (e: MouseEvent) => {
  e.preventDefault();
  rejectAll();
  trackResponse(false);
  cookiePrompt?.classList.remove("show");
};

const acceptAllBtn = document.querySelector("#js-cookie-prompt__accept-all");
acceptAllBtn?.addEventListener("click", acceptAll);

const rejectAllBtn = document.querySelector("#js-cookie-prompt__reject-all");
rejectAllBtn?.addEventListener("click", rejectAllCookies);
